:root {
  --main-content-max-width: 800px;
  --small-screen-side-margin: 15px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

p {
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: var(--main-content-max-width);
  height: auto;
  display: centre;
}

.companyBox {
  margin-top: 10vh;
}

.Top {
  margin-top: 15vh;
  margin-bottom: 10vh;
  margin-left: 20vw;
  margin-right: 150px;
  width: 100%;
  /*max-width: var(--main-content-max-width);
  margin: 0 auto;*/
}

.Top h1 {
  font-size: 64px;
  font-style: normal;
  font-weight: bold;
  margin-bottom: 15px;
}

.Top p {
  font-size: 16px;
  max-width: 600px;
}

hr.introDivider {
  height: 1px;
  opacity: 0.1;
  margin-bottom: 5vh;
}

.companyShowcase {
  margin-top: 5vh;
  margin-right: 20vw;
  margin-left: 20vw;
  margin-bottom: 15px;
  max-width: var(--main-content-max-width);
}

.companyText {
  margin-top: 35px;
  margin-bottom: 35px;
}
/*
img.logoImage {
  margin-bottom: 0px;
}
*/
img.nonLogoImage {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0, 4);
  border: 1px solid #f1f1f1;
}

@media only screen and (max-width: 767px) {
  .Top {
    margin-top: 10px;
    margin-left: var(--small-screen-side-margin);
    margin-right: var(--small-screen-side-margin);
    margin-bottom: 40px;
    line-height: 1.5;
  }

  .Top h1 {
    font-size: 50px;
    line-height: 1.2;
  }

  .Top p {
    margin-right: 0px;
  }

  hr.introDivider {
    margin-bottom: 20px;
  }

  .companyShowcase {
    margin-top: 20px;
    margin-right: var(--small-screen-side-margin);
    margin-left: var(--small-screen-side-margin);
  }

  .companyText {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  img {
    max-width: 100vw;
    height: auto;
  }

  img.logoImage {
    margin-bottom: -30px;
  }
}
